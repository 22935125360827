import { StrToListByChar } from "./StrToList";

export function capitalizeFirst(str) {
    let text = str
    if(str.includes(" ")) {
        text = ""
        StrToListByChar(str, " ").map(
            (item) => {
                text = text+" "+item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
            }
        );
    } else {
        text = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return text;
}