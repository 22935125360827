export function StrToList(str) {
    try {
        return str.split(";");
    } catch(e) {
        console.log(e);
        return [];
    }
}

export function StrToListByChar(str, char) {
    try {
        return str.split(char);
    } catch(e) {
        console.log(e);
        return [];
    }
}